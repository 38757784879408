import * as React from 'react';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { loadAssets, IAsset } from '@inwink/react-utils/loadassets';
import { saveFile } from '@inwink/utils/savefile';
import { Entities } from '@inwink/entities/entities';
import { States } from '../services/services';

export interface ICalendarGeneratorProps extends IBaseCalendarGeneratorProps {
    hasMultipleEvents?: boolean;
    type?: string;
    title: VisualTheme.IAppLabel;
    items?: any;
    className?: string;
    role?: string;
    icon?: JSX.Element;
    style?: any;
    tooltip?: string;
    i18nHelper?: Entities.i18nHelper;
    page?: States.ICurrentPageState;
}

export interface IBaseCalendarGeneratorProps {
    event: States.IEventState;
    i18n: States.i18nState;
}
interface IBaseCalendarGeneratorState {
    ready: boolean;
}

/**
 * @todo refacto (un seul generator) - gemery
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export abstract class BaseCalendarGeneratorComponent<P extends IBaseCalendarGeneratorProps,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    S extends IBaseCalendarGeneratorState> extends React.Component<P, S> {
    componentDidMount() {
        const prefix = (inwink.config.assetsUrl || "/");

        const icsAssets: IAsset[] = [
            {
                url: `${prefix}scripts/icsgeneration/ics.min.js`,
                type: "js",
                readyCheck: () => !!(window as any).ics,
                childs: [{
                    url: `${prefix}scripts/icsgeneration/timezonesdictionnary.js`,
                    type: "js",
                    readyCheck: () => !!(window as any).mappingIanaToWindows
                },
                {
                    url: `${prefix}scripts/icsgeneration/timezonesdictionnary.js`,
                    type: "js",
                    readyCheck: () => !!(window as any).timezoneInformations
                }]
            }
        ];
        loadAssets(icsAssets).then(() => {
            this.setState({
                // eslint-disable-next-line react/no-unused-state
                ready: true
            });
        });
    }

    abstract generate(arg: React.MouseEvent): void;

    abstract render(): JSX.Element;
}

export function downloadCalendar(calendar: Blob, title: string) {
    return saveFile(calendar, title + ".ics", "text/calendar");
}

export function getLocationDetails(props: IBaseCalendarGeneratorProps) {
    const locationDetails = [];
    const eventDetail = props.event?.detail;
    if (eventDetail?.addressDetail) {
        locationDetails.push(eventDetail.addressDetail);
    }
    if (eventDetail?.zipCode) {
        locationDetails.push(eventDetail.zipCode);
    }
    if (eventDetail?.city) {
        locationDetails.push(eventDetail.city);
    }
    if (eventDetail?.country) {
        locationDetails.push(eventDetail.country);
    }
    if (locationDetails?.length) {
        return locationDetails.join(', ');
    }
    return '';
}
