import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Entities } from '@inwink/entities/entities';
import { AppTextLabel, AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';
import { wrapReduxStore, IInwinkStore } from '../../store';
import { eventUserModule, userMessageModule, eventUserActionsModule } from '../../routes/appmodules';

import './requiredaccess.less';

export interface IRequireAllowNetworkingProps {
    event: States.IEventState;
    message?: string;
    theme?: string;
    i18n?: States.i18nState;
    i18nHelper?: Entities.i18nHelper;
    store?: IInwinkStore;
    onClick?: () => void;
}

@withI18nHelper()
class RequireAllowNetworkingComponent extends React.Component<IRequireAllowNetworkingProps, any> {
    allowNetworking = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();

        const store = this.props.store;
        const eventconf = this.props.event?.detail?.configuration;
        const mepage = this.props.event.data.templates.data
            .filter((ct) => ct.application === "userwrk" && ct.contentType === "page" && ct.tinyUrl === "userprofile")[0] ??
                this.props.event.data.templates.data
                    .filter((ct) => ct.application === "companion" && ct.contentType === "page" && ct.tinyUrl === "me")[0];
        if (eventconf.networking.useOnboarding && mepage) {
            eventUserModule().then((mod) => mod.meModule(null, null)).then((mod) => {
                userMessageModule().then((msgmod) => {
                    msgmod.userMessageActions.showModal(
                        this.props.i18nHelper,
                        mod.OnBoarding, "",
                        { networkingtemplate: mepage, disableNavigation: true },
                        "usernetworkingonboardingmodal"
                    )(store.dispatch, store.getState);
                });
            });
        } else {
            eventUserActionsModule().then((mod) => {
                mod.userNetworkingActions.allowNetworking(true)(store.dispatch, store.getState);
            });
        }

        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    render() {
        return <div className="requiredaccess">
            <div className="message">
                <AppLabel i18n="action.requireallownetworking" />
            </div>
            <div className="actions">
                <button
                    type="button"
                    onClick={this.allowNetworking}
                >
                    <AppTextLabel i18n="actions.allownetworking" />
                </button>
            </div>
        </div>;
    }
}

export const RequireAllowNetworking = wrapReduxStore(RequireAllowNetworkingComponent);