import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { ItemTemplateProps } from '@inwink/itemtemplate';
import { commonUxActions } from '../../services/uxactions';

interface ILogoutButtonProps extends ItemTemplateProps {
    commonUxActions?: typeof commonUxActions;
}

@withI18nHelper()
class LogoutButtonComponent extends React.Component<ILogoutButtonProps, any> {
    login = () => {
        return new Promise((resolve) => {
            const action = this.props.commonUxActions.logout() as any;
            action.then(() => {
                resolve();
            });
        });
    };

    render() {
        const i18n = this.props.i18nHelper;

        let txt = "Je me déconnecte";
        if (this.props.template.fields && this.props.template.fields[0] && this.props.template.fields[0].text) {
            txt = i18n.translateBag(this.props.template.fields[0].text as any);
        }
        return <AsyncButton onClick={this.login}>{txt}</AsyncButton>;
    }
}

function mapLogoutDispatchToProps(dispatch) {
    return {
        commonUxActions: bindActionCreators(commonUxActions, dispatch),
    };
}

export const LogoutButton: new (any) => React.Component<ILogoutButtonProps, any> = connect(
    null,
    mapLogoutDispatchToProps
)(LogoutButtonComponent as any) as any;
