import * as React from 'react';
import { saveFile } from '@inwink/utils/savefile';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { Loader } from '@inwink/loader';
import { connect } from 'react-redux';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper, translateBag } from '@inwink/i18n';
import { States } from '../services/services';
import { AppTextLabel } from '../services/i18nservice';

interface IBadgeDownloadProps {
    i18n: States.i18nState;
    type?: string;
    title?: VisualTheme.IAppLabel;
    className?: string;
    role?: string;
    icon?: JSX.Element;
    style?: any;
    isButton?: boolean;
    user?: States.IAppUserState;
    event?: States.IEventState;
    tooltip?: string;
    i18nHelper?: Entities.i18nHelper;
}

interface IBadgeDownloadState {
    isLoading: boolean;
}

@withI18nHelper()
class BadgeDownloadComponent extends React.Component<IBadgeDownloadProps, IBadgeDownloadState> {
    constructor(props: IBadgeDownloadProps) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    downloadBadge = () => {
        if (this.props.user && this.props.user.currentUser) {
            const badgeUrl = this.props.user.currentUser.detail && (this.props.user.currentUser.detail as any).badge;
            const firstname = this.props.user.currentUser.detail && this.props.user.currentUser.detail.firstname;
            const lastname = this.props.user.currentUser.detail && this.props.user.currentUser.detail.lastname;
            const eventName = this.props.event && this.props.event.detail && this.props.event.detail.tinyUrl;
            if (badgeUrl) {
                this.setState({ isLoading: true });
                fetch(badgeUrl).then((res) => res.blob())
                    .then((blob) => {
                        if (blob) {
                            saveFile(blob, `${firstname}-${lastname}-${eventName}-badge`, "application/pdf");
                            this.setState({ isLoading: false });
                        }
                    });
            }
        } else {
            return () => { };
        }
    };

    render() {
        const { i18nHelper } = this.props;
        let content;
        let innerContent;
        let title;

        if (!this.props.user || !this.props.user.currentUser) {
            return null;
        }

        if (!this.props.title) {
            title = <AppTextLabel i18n="downloadbadge.download" />;
        } else {
            title = translateBag(this.props.i18n, this.props.title);
        }

        if (this.state.isLoading) {
            let loadingMessage;
            if (i18nHelper && i18nHelper.translate) {
                loadingMessage = i18nHelper.translate("badgedownload.downloading");
            }
            innerContent = <Loader message={loadingMessage} />;
        } else {
            innerContent = <>
                {this.props.icon}
                {title}
            </>;
        }

        const props = {
            className: this.props.className,
            role: this.props.role,
            style: this.props.style,
            title: this.props.tooltip,
            onClick: this.downloadBadge
        } as any;

        if (this.props.isButton) {
            // On le force à être un bouton dans le cas où ne passe pas par un editeur d'action.
            content = <button type="button" {...props}>
                {innerContent}
            </button>;
        } else {
            content = <a {...props}>
                {innerContent}
            </a>;
        }

        return content;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user,
        event: state.event
    };
}

export const BadgeDownload: new (any) => React.Component<IBadgeDownloadProps, IBadgeDownloadState> = connect(
    mapStateToProps,
    null
)(BadgeDownloadComponent as any) as any;

export default BadgeDownload;

export function BadgeDownloadMySpaceButton(props: IBadgeDownloadProps) {
    return <div className="myspace-profilemodule" style={props.style}>
        <h4>
            <AppTextLabel i18n="networking.me.profile.badgedownload" />
        </h4>
        <BadgeDownload i18n={props.i18n} isButton />
    </div>;
}
