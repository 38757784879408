import * as React from 'react';
import { connect } from 'react-redux';
import { ItemTemplateProps } from '@inwink/itemtemplate';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { Loader } from '@inwink/loader';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { wrapReduxStore, IInwinkStore } from '../../store';
import { userMessageModule } from '../../routes/appmodules';
import { DynLabel, AppTextLabel } from '../../services/i18nservice';
import { States } from '../../services/services';

export interface ILoginButtonProps extends ItemTemplateProps {
    userstate?: States.IAppUserState;
    store?: IInwinkStore;
}

class LoginButtonComponent extends React.Component<ILoginButtonProps, any> {
    login = () => {
        return userMessageModule().then((mod) => {
            return mod.commonUxActions.showLogin()(this.props.store.dispatch, this.props.store.getState);
        });
    };

    render() {
        if (!this.props.userstate.checked) {
            return <AsyncButton><Loader /></AsyncButton>;
        }
        let txt: any = <AppTextLabel i18n="login" />;
        if (this.props.template.fields && this.props.template.fields[0] && this.props.template.fields[0].text) {
            txt = <DynLabel i18n={this.props.template.fields[0].text as VisualTheme.IAppLabel} />;
        }
        return <AsyncButton onClick={this.login}>{txt}</AsyncButton>;
    }
}

function mapLoginStateToProps(state: States.IAppState) {
    return {
        userstate: state.user
    };
}

export const LoginButton: new (any) => React.Component<ILoginButtonProps, any> = connect(
    mapLoginStateToProps,
    null
)(wrapReduxStore(LoginButtonComponent) as any) as any;
