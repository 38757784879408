import * as React from 'react';
import { sanitizeHtmlString } from '@inwink/utils/methods/sanitizehtmlstring';
import { replaceAll } from '@inwink/utils/methods/replaceall';
import { VisualTheme } from '@inwink/entities/visualtheme';

export interface ContentStyleProps {
    blocid?: string;
    contentid? : string;
    css : string;
    theme : VisualTheme.IBlocTheme;
}

export class ContentStyle extends React.PureComponent<ContentStyleProps, any> {    
    render(){
        var css = this.props.css;
        if (!css)
            return null;
            
        if (this.props.blocid){
            css = replaceAll(css, "##blocid", "#bl-" + this.props.blocid);
        }

        if (this.props.contentid){
            css = replaceAll(css, "##contentid", "#ct-" + this.props.contentid);
        }

        if (this.props.theme){
            css = replaceAll(css, "##defaultbg", (this.props.theme.style && this.props.theme.style.backgroundColor) || "");
            css = replaceAll(css, "##defaultbgtext", (this.props.theme.style && this.props.theme.style.color) || "");
            css = replaceAll(css, "##accentbg", (this.props.theme.accentBg && this.props.theme.accentBg.backgroundColor) || "");
            css = replaceAll(css, "##accentbgtext", (this.props.theme.accentBg && this.props.theme.accentBg.color) || "");
            css = replaceAll(css, "##accent2bg", (this.props.theme.accent2Bg && this.props.theme.accent2Bg.backgroundColor) || "");
            css = replaceAll(css, "##accent2bgtext", (this.props.theme.accent2Bg && this.props.theme.accent2Bg.color) || "");
        }        
        
        return <style dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(css, "style") }}></style>
    }
}