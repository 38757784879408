import * as React from 'react';
import { ContainerQuery, getActiveVisualStates } from '@inwink/responsive/containerquery';

export var mediaquery = {
    XXXXS: {
        minWidth: 0
    },
    XXXS: {
        minWidth: 320
    },
    XXS: {
        minWidth: 375
    },
    XS: {
        minWidth: 425
    },
    S: {
        minWidth: 768,
    },
    M: {
        minWidth: 1024,
    },
    L: {
        minWidth: 1366,
    },
    XL: {
        minWidth: 1600
    },
    XXL: {
        minWidth: 1900
    },
    XXXXXXL: {
        minWidth: 3840
    }
};

export class AppVisualState extends React.Component<React.PropsWithChildren<any>, any> {
    constructor(props) {
        super(props);
        let start = null;
        if (typeof window !== "undefined") {
            start = getActiveVisualStates(window.document.body.clientWidth, window.document.body.clientHeight, mediaquery);
        }

        this.state = {
            startstate: start,
            visualstate: start
        };
    }

    visualstatechanged = (visualstate) => {
        this.setState({ visualstate: visualstate });
    };

    render() {
        return <ContainerQuery
            className="approot"
            startUp={this.state.startstate}
            stateName="root-vstate"
            query={mediaquery}
            stateChanged={this.visualstatechanged}
        >
            {React.cloneElement(this.props.children as any, { visualstate: this.state && this.state.visualstate })}
        </ContainerQuery>;
    }
}
