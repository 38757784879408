import * as React from 'react';
import { useState, useEffect } from 'react';
import { ItemTemplateProps } from '@inwink/itemtemplate';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Entities } from '@inwink/entities/entities';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { templatedString } from '@inwink/utils/methods/templatedString';
import * as moment from 'moment';
import { States } from '@@services/services';

export interface IDateRangeFormatItem {
    startFormat?: string,
    endFormat?: string,
    template?: string
}

export interface IDateRangeFormat {
    sameMonth: IDateRangeFormatItem,
    sameYear: IDateRangeFormatItem,
    sameDay: IDateRangeFormatItem,
    default: IDateRangeFormatItem
}

export interface IDateRangeTemplateProps extends ItemTemplateProps {
    event: States.IEventState;
}
const defaultTemplate = "{start} - {end}";
const enFormat: IDateRangeFormat = {
    sameMonth: {
        startFormat: "DD",
        endFormat: "DD MMMM YYYY",
        template: defaultTemplate
    },
    sameYear: {
        startFormat: "DD MMMM",
        endFormat: "DD MMMM YYYY",
        template: defaultTemplate
    },
    sameDay: {
        startFormat: "DD MMMM YYYY, h:mm a",
        endFormat: "h:mm a",
        template: defaultTemplate
    },
    default: {
        startFormat: "DD MMMM YYYY",
        endFormat: "DD MMMM YYYY",
        template: defaultTemplate
    }
};

const defaultFormats: Record<string, IDateRangeFormat> = {
    $default: {
        sameMonth: {
            startFormat: "D",
            endFormat: "LL",
            template: defaultTemplate
        },
        sameYear: {
            startFormat: "LL",
            endFormat: "LL",
            template: defaultTemplate
        },
        sameDay: {
            startFormat: "LLL",
            endFormat: "HH:mm",
            template: defaultTemplate
        },
        default: {
            startFormat: "LL",
            endFormat: "LL",
            template: defaultTemplate
        }
    },
    en: enFormat
};

export function DateRangeTemplate(props: IDateRangeTemplateProps) {
    const [startDate, setStartDate] = useState<moment.Moment>(null);
    const [endDate, setEndDate] = useState<moment.Moment>(null);
    // const eventTimezone = props.event?.detail?.timeZone || props.data?.timeZone;
    useEffect(() => {
        if (props.template.properties?.startDate?.name) {
            const tmp = getPropertyValue(
                props.template.properties.startDate.useData ? props.data : props.datacontext,
                props.template.properties.startDate.name
            );
            if (tmp) {
                setStartDate(props.i18nHelper.getMoment(tmp));
            } else {
                setStartDate(null);
            }
        }

        if (props.template.properties?.endDate?.name) {
            const tmp = getPropertyValue(
                props.template.properties.endDate.useData ? props.data : props.datacontext,
                props.template.properties.endDate.name
            );
            if (tmp) {
                setEndDate(props.i18nHelper.getMoment(tmp));
            } else {
                setEndDate(null);
            }
        }
    }, [props.datacontext, props.i18nHelper]);

    return <DateRange
        defaultTemplate={props.template.properties?.defaultTemplate}
        format={props.template.properties?.format}
        lngformat={props.template.properties?.lngformat}
        startDate={startDate}
        endDate={endDate}
    />;

}
// export class DateRangeTemplate extends React.Component<IDateRangeTemplateProps, any> {
//     getFormat(lng: string, kind: string): IDateRangeFormatItem {
//         const buildInItem: IDateRangeFormatItem = defaultFormats.$default[kind];
//         const lngFormat = defaultFormats[lng];
//         const lngItem: IDateRangeFormatItem = lngFormat && lngFormat[kind];
//         const defaultCustomTemplate = this.props.template.properties?.defaultTemplate;
//         const templateFormat: IDateRangeFormatItem = this.props.template.properties?.format
//             && this.props.template.properties.format[kind];

//         const templateLngFormat = this.props.template.properties?.lngformat
//             && this.props.template.properties.lngformat[lng]
//             && this.props.template.properties.lngformat[lng][kind];

//         const result: IDateRangeFormatItem = {
//             startFormat: (templateLngFormat?.startFormat)
//                 || (templateFormat?.startFormat)
//                 || (lngItem?.startFormat)
//                 || buildInItem.startFormat,
//             endFormat: (templateLngFormat?.endFormat)
//                 || (templateFormat?.endFormat)
//                 || (lngItem?.endFormat)
//                 || buildInItem.endFormat,
//             template: (templateLngFormat?.template)
//                 || (templateFormat?.template)
//                 || defaultCustomTemplate
//                 || (lngItem?.template)
//                 || buildInItem.template
//         };

//         return result;
//     }

//     template(template: string, start: string, end: string) {
//         return templatedString(template, { start, end });
//     }

//     render() {
//         let startDate: moment.Moment = null;
//         let endDate: moment.Moment = null;

//         let text = null;
//         if (this.props.template.properties?.startDate?.name) {
//             const tmp = getPropertyValue(this.props.datacontext, this.props.template.properties.startDate.name);
//             if (tmp) {
//                 startDate = this.props.i18nHelper.getMoment(tmp);
//             }
//         }

//         if (this.props.template.properties?.endDate?.name) {
//             const tmp = getPropertyValue(this.props.datacontext, this.props.template.properties.endDate.name);
//             if (tmp) {
//                 endDate = this.props.i18nHelper.getMoment(tmp);
//             }
//         }

//         if (startDate && endDate) {
//             if (startDate.isSame(endDate, 'date')) {
//                 const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "sameDay");
//                 text = this.template(
//                     formatting.template,
//                     startDate.format(formatting.startFormat),
//                     endDate.format(formatting.endFormat)
//                 );
//                 // text = startDate.format(formatting.startFormat);
//             } else if (startDate.isSame(endDate, 'year') && startDate.isSame(endDate, 'month')) {
//                 const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "sameMonth");
//                 text = this.template(
//                     formatting.template,
//                     startDate.format(formatting.startFormat),
//                     endDate.format(formatting.endFormat)
//                 );
//             } else if (startDate.isSame(endDate, 'year') && !startDate.isSame(endDate, 'month')) {
//                 const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "sameYear");
//                 text = this.template(
//                     formatting.template,
//                     startDate.format(formatting.startFormat),
//                     endDate.format(formatting.endFormat)
//                 );
//             } else {
//                 const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "default");
//                 text = this.template(
//                     formatting.template,
//                     startDate.format(formatting.startFormat),
//                     endDate.format(formatting.endFormat)
//                 );
//             }
//         } else if (startDate) {
//             const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "default");
//             text = startDate.format(formatting.startFormat);
//         } else if (endDate) {
//             const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "default");
//             text = endDate.format(formatting.endFormat);
//         } else {
//             return null;
//         }

//         return <span className={"daterange" + (this.props.className ? " " + this.props.className : "")}>{text}</span>;
//     }
// }

export interface IDateRangeProps {
    className?: string;
    i18nHelper?: Entities.i18nHelper;
    defaultTemplate?: IDateRangeFormat;
    format?: IDateRangeFormat;
    lngformat?: Record<string, IDateRangeFormat>;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

@withI18nHelper()
export class DateRange extends React.Component<IDateRangeProps, any> {
    getFormat(lng: string, kind: string): IDateRangeFormatItem {
        const buildInItem: IDateRangeFormatItem = defaultFormats.$default[kind];
        const lngFormat = defaultFormats[lng];
        const lngItem: IDateRangeFormatItem = lngFormat && lngFormat[kind];
        const defaultCustomTemplate = this.props.defaultTemplate;
        const templateFormat: IDateRangeFormatItem = this.props.format
            && this.props.format[kind];

        const templateLngFormat = this.props.lngformat
            && this.props.lngformat[lng]
            && this.props.lngformat[lng][kind];

        const result: IDateRangeFormatItem = {
            startFormat: (templateLngFormat?.startFormat)
                || (templateFormat?.startFormat)
                || (lngItem?.startFormat)
                || buildInItem.startFormat,
            endFormat: (templateLngFormat?.endFormat)
                || (templateFormat?.endFormat)
                || (lngItem?.endFormat)
                || buildInItem.endFormat,
            template: (templateLngFormat?.template)
                || (templateFormat?.template)
                || defaultCustomTemplate
                || (lngItem?.template)
                || buildInItem.template
        };

        return result;
    }

    template(template: string, start: string, end: string) {
        return templatedString(template, { start, end });
    }

    render() {
        const startDate: moment.Moment = this.props.startDate;
        const endDate: moment.Moment = this.props.endDate;

        let text = null;

        if (startDate && endDate) {
            if (startDate.isSame(endDate, 'date')) {
                const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "sameDay");
                text = this.template(
                    formatting.template,
                    startDate.format(formatting.startFormat),
                    endDate.format(formatting.endFormat)
                );
                // text = startDate.format(formatting.startFormat);
            } else if (startDate.isSame(endDate, 'year') && startDate.isSame(endDate, 'month')) {
                const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "sameMonth");
                text = this.template(
                    formatting.template,
                    startDate.format(formatting.startFormat),
                    endDate.format(formatting.endFormat)
                );
            } else if (startDate.isSame(endDate, 'year') && !startDate.isSame(endDate, 'month')) {
                const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "sameYear");
                text = this.template(
                    formatting.template,
                    startDate.format(formatting.startFormat),
                    endDate.format(formatting.endFormat)
                );
            } else {
                const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "default");
                text = this.template(
                    formatting.template,
                    startDate.format(formatting.startFormat),
                    endDate.format(formatting.endFormat)
                );
            }
        } else if (startDate) {
            const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "default");
            text = startDate.format(formatting.startFormat);
        } else if (endDate) {
            const formatting = this.getFormat(this.props.i18nHelper.i18n.currentLanguageCode, "default");
            text = endDate.format(formatting.endFormat);
        } else {
            return null;
        }

        return <span className={"daterange" + (this.props.className ? " " + this.props.className : "")}>{text}</span>;
    }
}
