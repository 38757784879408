import * as React from 'react';
import type { ItemTemplateProps } from './itemtemplate.props';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { InwinkImage } from '@@components/images';
import { IAssetEntity } from '@@data/assets';

export interface IVideoBackgroundTemplatePartProps extends ItemTemplateProps {    
}

export interface IVideoBackgroundTemplatePartState {    
    loaded : boolean;
    videoReady : boolean;
}

// export interface IVideoAsset {
//     tenantId?: string;
//     id: string;
//     title: any;
//     thumbnail: any;
//     file: any;
// }

export class VideoBackgroundTemplatePart 
    extends React.Component<IVideoBackgroundTemplatePartProps, IVideoBackgroundTemplatePartState> {
    videoEltRef = React.createRef<HTMLVideoElement>();

    constructor(props) {
        super(props);

        const defered = this.readBool(this.props.template.properties?.defered, true);
        this.state = {
            loaded: !defered,
            videoReady: !defered
        };
    }

    componentDidMount(): void {
        const defered = this.readBool(this.props.template.properties?.defered, true);
        if (defered) {
            this.setState({
                loaded: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        videoReady: true
                    });
                }, 20);
            });
        }
    }

    readBool(prop: any, defaultVal: boolean) : boolean {
        const res = getPropertyValue(this.props.data, prop);
        if (!res) {
            return defaultVal;
        }
        if (typeof res === "boolean") {
            return res;
        }
        if (typeof res === "string") {
            return res === "true" || res === "1";
        }

        return defaultVal;
    }

    renderVideo(videoUrl: string) {  
        if (!videoUrl) {
            return null;
        }

        const properties = this.props.template.properties;
        const preload = getPropertyValue(this.props.data, properties?.preload);
        const loop = this.readBool(properties?.loop, true);
        const muted = this.readBool(properties?.muted, true);
        const autoplay = this.readBool(properties?.autoplay, true);
        const playsinline = this.readBool(properties?.playsinline, false);
        const videotype = getPropertyValue(this.props.data, properties?.videotype);

        return <video
            ref={this.videoEltRef}
            className={"iw-videobg-video" + (this.props.template.className || "")}
            controls={false}
            preload={preload}
            loop={loop}
            muted={muted}
            autoPlay={autoplay}
            style={{ opacity: this.state.videoReady ? 1 : 0}}
            playsInline={playsinline}>
            <source src={videoUrl} type={videotype} />
        </video>;
    }

    render() {
        const properties = this.props.template.properties;
        const video: string | IAssetEntity = getPropertyValue(this.props.data, properties?.target);
        if (!video) {
            return null;
        }
        
        if (typeof video === "string") {
            return this.renderVideo(video);
        } else if (typeof video === "object") {
            const videoUrl = typeof video.file == "object" ? video.file?.url : video.file;
            const defered = this.readBool(properties?.defered, true);
            let videoElt = null;
            if (!defered || this.state.loaded) {
                videoElt = this.renderVideo(videoUrl);
            }

            return <>
                {video.thumbnail ? <InwinkImage
                    className="iw-videobg-thumbnail"
                    image={video}
                    progressive
                    defaultSize={properties.imageDefaultSize}
                    availableSizes={properties.imageAvailableSizes}
                /> : null}
                {videoElt}
            </>;
        }
        
    }
}