import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withAppTrads } from '@@components/apptrads';
import { Entities } from '@inwink/entities/entities';
import { AppTextLabel, AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';
import { withUrlService } from '../urlstatecontext';

import './requiredaccess.less';
import { RequireRegistrationActions } from './requireregistration';

export interface IRequireLoginBaseProps {
    configuration?: Entities.IEventDetailConfiguration | States.ICommunityConfiguration;
    i18n?: States.i18nState;
    onClick?: (action: 'login' | 'registration') => void;
    options?: {
        exhibitorId: string;
        sessionId: string;
        tradsOptions?: IRequireLoginBaseTradsOptions
    }
}

export interface IRequireLoginBaseComponentProps extends IRequireLoginBaseProps{
    onShowLogin: () => Promise<any>;
    match?: States.ILocationMatch
    history?: any;
    urlservice?: States.IAppUrlContext;
}

export interface IRequireLoginBaseTradsOptions {
    requireLoginPrefix: string;
    requireRegistrationPrefix: string;
    requireLoginAndRegistration: string;
}
@withUrlService()
@withAppTrads()
class RequireLoginBaseComponent extends React.Component<IRequireLoginBaseComponentProps, any> {
    login = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        if (this.props.onClick) {
            this.props.onClick("login");
        }
        return this.props.onShowLogin();
    };

    renderRegistrationBloc() {
        const options = this.props.options;
        const actions = RequireRegistrationActions({
            configuration: this.props.configuration,
            tradKeyPrefix: options?.tradsOptions?.requireRegistrationPrefix || "action.requireregistration",
            exhibitorId: options?.exhibitorId,
            history: this.props.history,
            i18n: this.props.i18n,
            match: this.props.match,
            sessionId: options?.sessionId,
            urlservice: this.props.urlservice,
            onClick: () => {
                if (this.props.onClick) {
                    this.props.onClick("registration");
                }
            }
        });
        if (actions) {
            return <div className="registration">
                <div className="message">
                    <AppLabel
                        i18n={options?.tradsOptions?.requireLoginAndRegistration || "action.requireloginandregistration"}
                    />
                </div>
                {actions}
            </div>;
        }

        return null;
    }

    render() {
        const options = this.props.options;
        const conf = this.props.configuration;
        const login = (conf && conf.companion && !conf.companion.disableUserCanLogin) ? <div className="login">
            <div className="message">
                <AppLabel i18n={options?.tradsOptions?.requireLoginPrefix || "action.requirelogin"} />
            </div>
            <div className="actions">
                <button type="button" className="login-button link-inwink" onClick={this.login}>
                    <AppTextLabel i18n={(options?.tradsOptions?.requireLoginPrefix || "action.requirelogin") + ".button"} />
                </button>
            </div>
        </div> : null;
        return <div className="requiredaccess">
            {this.renderRegistrationBloc()}
            {login}
        </div>;
    }
}

export const RequireLoginBase: new (props: IRequireLoginBaseComponentProps)
=> React.Component<IRequireLoginBaseComponentProps, any> = withRouter(RequireLoginBaseComponent as any) as any;
