import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { AppUserRestrictedComponent } from '@@components/appuser.restricted';
import type { States } from '@@services/services';
import type {ItemTemplateProps} from './itemtemplate.props';
import { ItemTemplate } from './itemtemplate';

export interface UserRestrictedTemplateProps extends ItemTemplateProps {
    page: any;
}

@connectwith((state: States.IAppState) => {
    return {
        page: state.pages.currentPage
    };
})
export class UserRestrictedTemplate extends React.Component<UserRestrictedTemplateProps, any> {
    render() {
        let content = null;
        if (this.props.template.blocs) {
            const child = {                
                type: "div",
                className: this.props.template.className,
                blocs: this.props.template.blocs,
            };

            content = <ItemTemplate
                {...this.props}
                template={child}
            />;
        }

        return <AppUserRestrictedComponent page={this.props.page}>
            {content}
        </AppUserRestrictedComponent>;
    }
}
