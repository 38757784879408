import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';

import './requiredaccess.less';

export interface IRequireMembershipLevelProps {
    community?: States.ICommunityState;
    message?: string;
    theme?: string;
    i18n?: States.i18nState;
    onClick?: () => void;
    history?: any;
    match?: States.ILocationMatch;
}

class RequireMembershipLevelComponent extends React.Component<IRequireMembershipLevelProps, any> {
    renderMembershipLevelActions() {
        let btn;
        if (btn) {
            return <div className="actions">
                {btn}
            </div>;
        }

        return null;
    }

    render() {
        return <div className="requiredaccess">
            <div className="message">
                <AppLabel i18n="action.community.requiremembershiplevel" />
            </div>
            {this.renderMembershipLevelActions()}
        </div>;
    }
}

export const RequireMembershipLevel: new (props: IRequireMembershipLevelProps)
=> React.Component<IRequireMembershipLevelProps, any> = withRouter(RequireMembershipLevelComponent as any) as any;
