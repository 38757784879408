import * as React from 'react';
import { connect } from 'react-redux';
import { AppSplash as _AppSplash } from '@inwink/appsplash';
import { States } from '../services/services';
import { getTrads, getVisualConfiguration } from '../data/templates';

export interface IAppSplashProps {
    splashState?: States.IAppSplashState;
    i18n?: States.i18nState;
    event?: States.IEventState;
    community?: States.IEventState;
    rootwebsite?: States.IRootWebsiteState;
    relatedToId?: string;
    relatedTo?: string;
    children?: React.ReactNode;
}

function AppSplashComponent(props: IAppSplashProps) {
    let visualconf = props.splashState.visualconf;
    const isEvent = props.relatedTo === "event";
    if (!visualconf) {
        const data = props.community?.data || props.event?.data || props.rootwebsite?.data;
        const visualconfig = data && getVisualConfiguration(data);
        visualconf = visualconfig?.splashscreen;
        if (visualconf && !visualconf.logoAlt) {
            visualconf.logoAlt = isEvent ? props.event?.detail?.title : '';
        }
    }

    // eslint-disable-next-line react/jsx-pascal-case
    return <React.Suspense fallback={<React.Fragment />}>
        <_AppSplash
            serverside={__SERVERSIDE__}
            customSplashScreen={null}
            i18n={props.i18n}
            relatedToId={props.relatedToId}
            splashState={props.splashState}
            trads={isEvent ? getTrads(props.event?.data) : getTrads(props.rootwebsite?.data)}
            visualConfiguration={visualconf}
        >
            {props.children}
        </_AppSplash>
    </React.Suspense>;
}

function mapStateToProps(state: States.IAppState) {
    let relatedTo = null;
    let relatedToId = null;

    if (state.rootwebsite?.detail) {
        relatedTo = "rootwebsite";
        relatedToId = state.rootwebsite.detail.id;
    }
    if (state.event?.detail) {
        relatedTo = "event";
        relatedToId = state.event.detail.id;
    }
    if (state.community?.detail) {
        relatedTo = "community";
        relatedToId = state.community.communityid;
    }

    return {
        splashState: state.appsplash,
        i18n: state.i18n,
        event: state.event,
        community: state.community,
        rootwebsite: state.rootwebsite,
        relatedTo,
        relatedToId
    };
}

export const AppSplash : React.FunctionComponent<IAppSplashProps> = connect(mapStateToProps)(AppSplashComponent as any) as any;
