import * as React from 'react';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import type { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import type { ItemTemplateProps } from './itemtemplate.props';

interface IInwinkFileLinkProps extends ItemTemplateProps {
    icon?: string;
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
export class InwinkFileLink extends React.Component<IInwinkFileLinkProps, any> {
    render() {
        if (!this.props.template) {
            return null;
        }

        if (this.props.template.requireConnexion && !this.props.user) {
            return null;
        }

        if (this.props.template.requireRegistration && this.props.user && !this.props.user.isRegistered) {
            return null;
        }

        const i18n = this.props.i18nHelper;
        let name = "";
        let to = "";
        if (this.props.template.fields && this.props.template.fields[0]) {
            let field = getPropertyValue(this.props.data, this.props.template.fields[0].name);
            if (field) {
                if (!field.url) {
                    field = i18n.translateBag(field);
                }

                if (field.url) {
                    to = field.url;
                }

                if (field.name) {
                    name = field.name;
                }
            }
        }
        return <a
            className={this.props.template.className}
            href={to}
            role={this.props.template.role}
            rel={this.props.template.rel}
            target={this.props.template.target}
            style={this.props.template.style}
        >
            {this.props.template.properties?.icon ? <i className={this.props.template.properties.icon} /> : null}
            {name}
        </a>;
    }
}
