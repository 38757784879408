import * as React from 'react';
import { userMessageModule } from '../../routes/appmodules';
import { IInwinkStore, wrapReduxStore } from '../../store';
import { RequireLoginBase, IRequireLoginBaseProps } from './requirelogin.base';

export interface IRequireLoginProps extends IRequireLoginBaseProps {
    store?: IInwinkStore;
}


class RequireLoginComponent extends React.Component<IRequireLoginProps, any> {
    login = () => {
        return userMessageModule().then((mod) => {
            const store = this.props.store;
            return mod.loginActions.showLogin()(store.dispatch, store.getState);
        });
    };

    render() {
        return <RequireLoginBase
            {...this.props}
            onShowLogin={this.login}
        />;
    }
}

export const RequireLogin = wrapReduxStore(RequireLoginComponent);
