import * as React from 'react';
import { States } from 'services/services';
import * as momenttimezone from 'moment-timezone';
import { getMoment } from '@inwink/i18n/utils/getmoment';
import { connect } from 'react-redux';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';

export const formatDateWithoutTimezone = "YYYY[-]MM[-]DD[T]HH[:]mm[:]ss";

interface IDisplayTimeZoneDateProps {
    event: States.IEventState;
    i18n: States.i18nState;
    date: string;
    hideUtc?: boolean;
    format?: any;
    className?: string;
    template?: any;
    data?: any;
    datacontext?: any;
}

function DisplayTimeZoneDateComponent(props: IDisplayTimeZoneDateProps) {
    let data = props.data;
    if (props.template?.fromcontext) {
        data = props.datacontext;
    }
    let className = props.template?.className || props.className;
    if (props.template?.blocStyle) {
        className += (" bloc-" + props.template.blocStyle);
    }
    return <span className={className}>
        {getTimeZoneDateDisplay(props.date, props.event?.detail, props.i18n,
            props.format, props.hideUtc, props.template, data)}
    </span>;
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        i18n: state.i18n
    };
}

export const DisplayTimeZoneDate: new (any) => React.Component<IDisplayTimeZoneDateProps, any> = connect(
    mapStateToProps, null
)(DisplayTimeZoneDateComponent as any) as any;

export function getTimeZoneDate(date: string | moment.Moment | Date, eventDetail: Entities.IEventDetail,
    i18n: States.i18nState, template?: any, data?: any): moment.Moment {
    let newDate;
    let dte = date;
    const isFromTemplate = template;
    const timeDisplay = eventDetail?.configuration?.global?.timeDisplay || "Default";
    if (isFromTemplate && data) {
        dte = getPropertyValue(data, template.date);
    }

    if (dte) {
        if (timeDisplay) {
            const eventTimezone = eventDetail && eventDetail.timeZone;
            if (timeDisplay === "Default" || timeDisplay === "Utc") {
                newDate = momenttimezone.tz(dte, eventTimezone);
            } else {
                newDate = momenttimezone.tz(dte, eventTimezone);
                if (newDate) {
                    const userTimezone = i18n?.userTimezone
                        || Intl.DateTimeFormat().resolvedOptions().timeZone;
                    newDate = momenttimezone.tz(newDate, userTimezone);
                }
            }
        } else {
            newDate = getMoment(i18n, dte);
        }
    }

    return newDate;
}

export function getTimeZoneDateDisplay(date: string | moment.Moment, eventDetail: Entities.IEventDetail, i18n: States.i18nState,
    format?: string, hideUtc?: boolean, template?: any, data?: any): string {
    let frmt = format;
    let hdutc = hideUtc;
    const newDate = getTimeZoneDate(date, eventDetail, i18n, template, data);
    const isFromTemplate = template;
    const timeDisplay = eventDetail?.configuration?.global?.timeDisplay || "Default";
    if (isFromTemplate) {
        frmt = template.format ? template.format : "LLL";
        hdutc = template.hideUtc || timeDisplay === "Default";
    }

    if (!frmt) {
        frmt = "LLL";
    } else if (typeof frmt !== "string") {
        frmt = frmt[i18n && i18n.currentLanguageCode] ? frmt[i18n.currentLanguageCode] : frmt[i18n.defaultlng];
    }

    if (newDate) {
        // console.log(`display date with locale : ${newDate.locale()}`);
        if (timeDisplay) {
            if (timeDisplay === "Utc") {
                return newDate.format(frmt + (hdutc ? "" : " z"));
            }
        }
        return newDate.format(frmt);
    }

    return '';
}
