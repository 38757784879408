import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Link } from 'react-router-dom';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { States } from '../../services/services';
import { ItemTemplateProps } from './itemtemplate.props';
import { withUrlService } from '../urlstatecontext';

export interface IInwinkLinkProps extends ItemTemplateProps {
    urlservice?: States.IAppUrlContext;
}
@withI18nHelper()
@withUrlService()
export class InwinkLink extends React.Component<IInwinkLinkProps, any> {
    render() {
        if (!this.props.template) {
            return null;
        }

        if (this.props.template.requireConnexion && !this.props.user) {
            return null;
        }

        if (this.props.template.requireRegistration && this.props.user && !this.props.user.isRegistered) {
            return null;
        }

        const i18n = this.props.i18nHelper;
        const urlservice = this.props.urlservice;
        let name; let
            to = "";
        if (this.props.template.fields && this.props.template.fields[0]) {
            name = getPropertyValue(this.props.data, this.props.template.fields[0].name);
        }
        if (this.props.template.link && this.props.template.link.url) {
            to = getPropertyValue(this.props.data, (this.props.template.link as any).url);
        }
        return <Link
            className={this.props.template.className}
            to={urlservice.pageUrl(to)}
            role={this.props.template.role}
            rel={this.props.template.rel}
            target={this.props.template.target}
            style={this.props.template.style}
        >
            {i18n.translateBag(name)}
        </Link>;
    }
}
