import type { States } from '@@services/services';
import type { Entities } from '@inwink/entities/entities';
import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { AssetImage } from './image';
import { IAssetEntity } from '@@data/assets';

export interface IInwinkImageProps { 
    className?: string,
    defaultSize: number;
    availableSizes: number[];
    image: string | IAssetEntity,
    event?: States.IEventState,
    community?: States.ICommunityState,
    i18nHelper?: Entities.i18nHelper,
    lazy?: boolean;
    progressive?: boolean;
}

function InwinkImageComponent(props: IInwinkImageProps) {
    return <AssetImage
        className={props.className}
        eventid={props.event?.eventid}
        communityid={props.community?.communityid}
        defaultSize={props.defaultSize}
        availableSizes={props.availableSizes}
        lazy={props.lazy}
        progressive={props.progressive}
        image={props.image}
        languages={props.i18nHelper.i18n.supported}
        displayLanguage={props.i18nHelper.i18n.currentLanguageCode}
    />;
}

export const InwinkImage : (p:IInwinkImageProps) => any = withI18nHelper()(connectwith((state) => {
    return {
        event: state.event,
        community: state.community
    };
})(InwinkImageComponent));