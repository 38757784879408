import * as React from 'react';
import { pathCombine } from '@inwink/utils/methods/pathCombine';
import { States } from '../services/services';
import { setUrlState } from '../services/index';

export const UrlServiceContext = React.createContext<States.IAppUrlContext>(null);
export interface IUrlStateContextProps {
    baseUrl: string;
    i18n: States.i18nState;
    noLanguage?: boolean;
    appUrlService?: States.IAppUrlContext;
    parenturlservice?: States.IAppUrlContext;
    children?: React.ReactNode;
}

@withParentUrlService()
export class UrlStateContext extends React.Component<IUrlStateContextProps, any> {
    constructor(props) {
        super(props);

        const urlservice = this.getUrlService();
        if (!__SERVERSIDE__) {
            setUrlState(this, urlservice);
        }

        this.state = {
            urlservice: this.getUrlService()
        };
    }

    componentDidUpdate(prevprops: IUrlStateContextProps) {
        if (this.props.baseUrl !== prevprops.baseUrl) {
            const urlservice = this.getUrlService();
            setUrlState(this, this.state.urlservice);

            this.setState({
                urlservice: urlservice
            });
        }
    }

    componentWillUnmount(): void {
        setUrlState(this, this.props.parenturlservice);
    }

    getUrlService() {
        if (this.props.appUrlService) {
            return this.props.appUrlService;
        }
        const urlservice: States.IAppUrlContext = {
            pageUrl: (url: string, noLanguage?: boolean) => {
                const useNoLanguage = this.props.noLanguage || noLanguage;
                if (!useNoLanguage
                    && this.props.i18n.supported
                    && this.props.i18n.supported.length > 1) {
                    return "/" + pathCombine(this.props.baseUrl, this.props.i18n.currentLanguageCode, url);
                }
                return "/" + pathCombine(this.props.baseUrl, url);
            },
            contentUrl: (url: string) => {
                return (inwink.config.assetsUrl || "/") + url;
            }
        };

        return urlservice;
    }

    render() {
        return <UrlServiceContext.Provider value={this.state.urlservice}>{this.props.children}</UrlServiceContext.Provider>;
    }
}

function withParentUrlService() {
    return (target) => {
        // eslint-disable-next-line react/display-name
        return React.forwardRef((props, ref) => <UrlServiceContext.Consumer>
            {(urlservice) => React.createElement(target, { ...props, parenturlservice: urlservice, ref: ref })}
        </UrlServiceContext.Consumer>) as typeof target;
    };
}

export function withUrlService() {
    return (target) => {
        // eslint-disable-next-line react/display-name
        return React.forwardRef((props, ref) => <UrlServiceContext.Consumer>
            {(urlservice) => React.createElement(target, { ...props, urlservice: urlservice, ref: ref })}
        </UrlServiceContext.Consumer>) as typeof target;
    };
}
