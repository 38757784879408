import * as React from 'react';
import './sociallinks.less';

export interface ISocialLinksProps {
    className?: string;
    entity: any;
    showMail?: boolean;
    showWebsite?: boolean;
}

export class SocialLinks extends React.Component<ISocialLinksProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            links: []
        };
    }

    onClick = (arg, link, disableopenexternal?: boolean) => {
        arg.preventDefault();
        arg.stopPropagation();
        window.open(link, disableopenexternal ? "_self" : "_blank");
    };

    externalUrl = (url) => {
        if (!(url.indexOf("http://") >= 0) && !(url.indexOf("https://") >= 0)) {
            return "http://" + url;
        }

        return url;
    };

    linkto = (protocol, domain, token) => {
        if (token.indexOf(domain) >= 0) {
            if (!(token.indexOf("http://") >= 0) && !(token.indexOf("https://") >= 0)) {
                return protocol + token;
            } else {
                return token;
            }
        } else {
            return protocol + domain + token;
        }
    };

    linkedinUrl = (protocol, domain, token) => {
        if (token.indexOf(domain) >= 0) {
            if (!(token.indexOf("http://") >= 0) && !(token.indexOf("https://") >= 0)) {
                return protocol + token;
            } else {
                return token;
            }
        } else {
            if (token.toLowerCase().indexOf("/company/") >= 0 || token.toLowerCase().indexOf("company/") >= 0) {
                return protocol + domain + token;
            } else {
                return protocol + domain + "in/" + token;
            }
        }
    };

    cleanName(link) {
        if (link && typeof link === "string")
            return link;
        return '';
    }

    renderLinks = () => {
        const links = [];

        if (this.props.entity) {
            const website = this.cleanName(this.props.entity.website);
            const email = this.cleanName(this.props.entity.mail);
            const twitter = this.cleanName(this.props.entity.twitter || this.props.entity.twitterid);
            const linkedin = this.cleanName(this.props.entity.linkedin || this.props.entity.linkedinurl);
            const facebook = this.cleanName(this.props.entity.facebook || this.props.entity.facebookurl);
            const github = this.cleanName(this.props.entity.github);
            const instagram = this.cleanName(this.props.entity.instagram);
            const tiktok = this.cleanName(this.props.entity.tiktok);
            const youtube = this.cleanName(this.props.entity.youtube);
            if (website && this.props.showWebsite !== false) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.externalUrl(website))}
                    key="website" className="sociallink clickable">
                    <i className="icon inwink-web bloc-accentbg"></i> <span className="label">{website}</span>
                </div>);
            }
            if (this.props.showMail && email) {
                links.push(<div onClick={(arg) => this.onClick(arg, "mailto:" + email, true)}
                    key="mail" className="sociallink clickable">
                    <i className="icon inwink-email bloc-accentbg"></i> <span className="label">{email}</span>
                </div>);
            }
            if (twitter) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkto("https://", "twitter.com/", twitter))}
                    key="twitter" className="sociallink clickable">
                    <i className="icon inwink-twitter-logo"></i> <span className="label">{twitter}</span>
                </div>);
            }
            if (linkedin) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkedinUrl("https://", "linkedin.com/", linkedin))}
                    key="linkedin" className="sociallink clickable">
                    <i className="icon inwink-linkedin-big-logo"></i> <span className="label">{linkedin}</span>
                </div>);
            }
            if (facebook) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkto("https://", "facebook.com/", facebook))}
                    key="facebook" className="sociallink clickable">
                    <i className="icon inwink-facebook-logo"></i> <span className="label">{facebook}</span>
                </div>);
            }
            if (github) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkto("https://", "github.com/", github))}
                    key="github" className="sociallink clickable">
                    <i className="icon inwink-github-logo"></i> <span className="label">{github}</span></div>);
            }
            if (instagram) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkto("https://", "www.instagram.com/", instagram))}
                    key="instagram" className="sociallink clickable">
                    <i className="icon inwink-instagram-logo"></i> <span className="label">{instagram}</span>
                </div>);
            }
            if (youtube) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkto("https://", "www.youtube.com/", youtube))}
                    key="youtube" className="sociallink clickable">
                    <i className="icon inwink-youtube"></i> <span className="label">{youtube}</span></div>);
            }
            if (tiktok) {
                links.push(<div onClick={(arg) => this.onClick(arg, this.linkto("https://", "www.tiktok.com/", tiktok))}
                    key="tiktok" className="sociallink clickable">
                    <i className="icon inwink-tiktok-logo"></i> <span className="label">{tiktok}</span>
                </div>);
            }
        }
        return links;
    };

    render() {
        return <div className={"sociallinks " + (this.props.className || '')}>
            {this.renderLinks()}
        </div>;
    }
}