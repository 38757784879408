import * as React from 'react';
import { getItemTemplatePart, hasAsyncItemTemplatePart, loadAsyncItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { ItemTemplateProps } from './itemtemplate.props';
// import { SessionActions } from './entityactions.session';
// import { PersonActions } from './entityactions.person';
// import { SpeakerActions } from './entityactions.speaker';
// import { EventMessageActions } from './entityactions.eventmessage';
import "./entityactions.less";

export class EntityActions extends React.Component<ItemTemplateProps, any> {
    templatepart(name) {
        const part = getItemTemplatePart(name);
        if (part) {
            return React.createElement(part, this.props);
        }

        if (hasAsyncItemTemplatePart(name)) {
            loadAsyncItemTemplatePart(
                name,
                this.props.store?.dispatch,
                this.props.store?.getState
            ).then(() => {
                this.setState({});
            });
        }
        return null;
    }

    render() {
        if (this.props.datacontext) {
            const ctx = this.props.datacontext;
            const name = "entityactions." + ctx.entityKind.toLowerCase();
            return this.templatepart(name);
            // if (ctx.entityKind === "Session") {
            //     return this.templatepart("entityactions.session");
            // } if (ctx.entityKind === "Exhibitor") {
            //     return this.templatepart("entityactions.exhibitor");
            // } if (ctx.entityKind === "Speaker") {
            //     return this.templatepart("entityactions.speaker");
            // } if (ctx.entityKind === "Person") {
            //     return this.templatepart("entityactions.person");
            // } if (ctx.entityKind === "EventMessage") {
            //     return this.templatepart("entityactions.eventmessage");
            // }
        }
        return <div className="entity-actions" />;
    }
}
