import { toUrlSlug } from '@inwink/utils/methods/tourlslug';
import { combinePath } from '@inwink/utils/methods/urls';
import * as React from 'react';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { IAssetEntity } from '@@data/assets';
import { States } from '@@services/services';

// export interface IImageAsset {
//     tenantId?: string;
//     id: string;
//     title: any;
//     thumbnail?: any;
//     file: any;
//     urlslug?: any;
// }

export interface IAssetImageProps {
    className?: string,
    defaultSize: number;
    availableSizes: number[];
    image: string | IAssetEntity;
    eventid?: string;
    event?: States.IEventState;
    communityid?: string;
    community?: States.ICommunityState;
    languages: string[];
    displayLanguage: string;
    lazy?: boolean;
    progressive?: boolean;
}

export function getImageTitle(image: IAssetEntity, languages: string[], displayLanguage: string) {
    const title = typeof image.title == "object" ? image.title[displayLanguage] : null;
    return title;
}

export function getImagePath(
    image: string | IAssetEntity,
    languages: string[], displayLanguage: string,
    eventid?: string,
    communityid?: string
) {
    if (image === "string") {
        return image;
    } else if (typeof image === "object") {
        const imgTitle = getImageTitle(image, languages, displayLanguage);
        const thumbnailAsset = image.thumbnail ? true : false;
        let imgPath = "";
        if (image.tenantId) {
            imgPath = `tenant/${image.tenantId}/asset/${image.id}/${thumbnailAsset ? "thumbnail" : "file"}`;
        } else if (eventid) {
            imgPath = `event/${eventid}/asset/${image.id}/${thumbnailAsset ? "thumbnail" : "file"}`;
        } else if (communityid) {
            imgPath = `community/${communityid}/asset/${image.id}/${thumbnailAsset ? "thumbnail" : "file"}`;
        }

        let slug = "image";

        if (imgPath && imgTitle) {
            slug = toUrlSlug(imgTitle);
            if (image.urlslug && image.urlslug[displayLanguage]) {
                slug = image.urlslug[displayLanguage];
            }
        }
        if (languages.length > 1) {
            imgPath = `${imgPath}/${displayLanguage}/${slug}`;
        } else {
            imgPath = `${imgPath}/${slug}`;
        }

        return combinePath(inwink.config.assetsApiUrl, imgPath);
    }
}

interface IProgressiveImageState {
    loaded: boolean;
    ready: boolean;
}

class ProgressiveImage extends React.PureComponent<IAssetImageProps, IProgressiveImageState> {
    imgElt = React.createRef<HTMLImageElement>();

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            ready: false
        };
    }

    componentDidMount(): void {
        if (this.imgElt.current) {
            if (this.imgElt.current.complete && this.imgElt.current.naturalWidth !== 0) {
                this.setState({ loaded: true }, () => {
                    setTimeout(() => {
                        this.setState({ ready: true });
                    }, 10);
                });
            } else {
                this.imgElt.current.onload = () => {
                    this.setState({ loaded: true }, () => {
                        setTimeout(() => {
                            this.setState({ ready: true });
                        }, 10);
                    });
                };
            }
        }
    }

    render() {
        const img = this.props.image as IAssetEntity;
        const imgTitle = getImageTitle(img, this.props.languages, this.props.displayLanguage);

        const pictureUrl = getImagePath(
            img, this.props.languages, this.props.displayLanguage, this.props.eventid, this.props.communityid);

        const srcSet = [];
        const webPSrcSet = [];

        this.props.availableSizes?.forEach((s) => {
            srcSet.push(`${pictureUrl}?mw=${s} ${s}w`);
            webPSrcSet.push(`${pictureUrl}.webp?mw=${s} ${s}w`);
        });

        return <>
            <picture className={"progressiveimage preview " + this.props.className}>
                <source srcSet={`${pictureUrl}.webp?mw=100&b=8&q=70`} type="image/webp" />
                <source srcSet={`${pictureUrl}?mw=100&b=8&q=70`} />
                <img
                    ref={this.imgElt}
                    loading={this.props.lazy ? "lazy" : "eager"}
                    className={this.props.className}
                    src={`${pictureUrl}?mw=100&b=8&q=70`}
                    alt={imgTitle} />
            </picture>

            {this.state.loaded ? <picture
                className={"progressiveimage large " + this.props.className}
                style={{
                    opacity: this.state.ready ? 1 : 0,
                    transition: "opacity 400ms ease-in"
                }}>
                <source srcSet={webPSrcSet.join(", ")} type="image/webp" />
                <source srcSet={srcSet.join(", ")} />
                <img
                    loading={this.props.lazy ? "lazy" : "eager"}
                    className={this.props.className}
                    src={pictureUrl + `?mw=${this.props.defaultSize || 600}`}
                    alt={imgTitle} />
            </picture> : null}
        </>;
    }
}

function AssetImageComponent(props: IAssetImageProps) {
    if (!props.image) return null;
    if (typeof props.image === "string") {
        return <img className={props.className} loading={props.lazy ? "lazy" : "eager"} src={props.image} />;
    } else {
        if (props.image.file && props.image.id && inwink.config.assetsApiUrl) {
            if (props.progressive) {
                return <ProgressiveImage {...props} />;
            }

            const imgTitle = getImageTitle(props.image, props.languages, props.displayLanguage);
            const pictureUrl = getImagePath(
                props.image, props.languages, props.displayLanguage, props.eventid, props.communityid);

            const srcSet = [];
            const webPSrcSet = [];

            props.availableSizes?.forEach((s) => {
                srcSet.push(`${pictureUrl}?mw=${s} ${s}w`);
                webPSrcSet.push(`${pictureUrl}.webp?mw=${s} ${s}w`);
            });


            return <picture className={props.className}>
                <source srcSet={webPSrcSet.join(", ")} type="image/webp" />
                <source srcSet={srcSet.join(", ")} />
                <img
                    loading={props.lazy ? "lazy" : "eager"}
                    className={props.className}
                    src={pictureUrl + `?mw=${props.defaultSize || 600}`}
                    alt={imgTitle} />
            </picture>;
            //picture = <img className="headerpicture" src={pictureUrl + "?mw=600"} srcSet={srcSet.join(", ")} />;            
        }
    }
}

export const AssetImage: (p: IAssetImageProps) => any = withI18nHelper()(connectwith((state: States.IAppState) => {
    return {
        event: state.event,
        community: state.community
    };
})(AssetImageComponent));