import { VisualTheme } from '@inwink/entities/visualtheme';
import * as assign from 'lodash/assign';
import * as React from 'react';
import { DynLabel } from '../services/i18nservice';
import { AppLink } from './applink';
import './contentpagelink.less';
import { IDynamicBlocBaseProps } from './dynamicpage/common';

export interface IContentPageLinkProps extends IDynamicBlocBaseProps {
    link: VisualTheme.IContentPageLink,
    title?: string;
}

export class ContentPageLink extends React.Component<IContentPageLinkProps, any> {
    updateText = (text, key) => {
        const link = assign({}, this.props.link, { [key]: text }) as VisualTheme.IContentPageLink;
        const links = this.props.template.properties.links.slice(0) as VisualTheme.IContentPageLink[];
        const idx = links.indexOf(this.props.link);
        links[idx] = link;
        const properties = assign({}, this.props.template.properties, { links });
        const template = assign({}, this.props.template, { properties });
        this.props.patchContent(template);
    };

    render() {
        let icon; let label; let desc; let
            onClick;
        const classes = ["contentpagelink"];
        if (this.props.className) {
            classes.push(this.props.className);
        }
        if (this.props.link.icon) {
            if (this.props.link.icon.className) {
                icon = <div className={"cssicon " + this.props.link.icon.className} />;
            }
        }
        if (this.props.link.displayOptions && this.props.link.displayOptions.isSecondary) {
            classes.push("lightbtn");
        }

        if (this.props.link.label) {
            label = <DynLabel
                editable={this.props.editable}
                onEdit={(text) => this.updateText(text, 'label')}
                component="h4"
                className="title"
                i18n={this.props.link.label}
            />;
        }

        if (this.props.link.description) {
            desc = <DynLabel
                editable={this.props.editable}
                onEdit={(text) => this.updateText(text, 'description')}
                component="div"
                className="desc bloc-lighttext"
                i18n={this.props.link.description}
            />;
        }

        let lnk = this.props.link.link;
        if (!lnk && this.props.link.page) {
            lnk = {
                content: this.props.link.page
            };
        }

        if (lnk) {
            return <AppLink
                {...this.props}
                id={this.props.link?.id}
                title={this.props.title}
                className={classes.join(" ")}
                role={this.props.link.role}
                theme={undefined}
                link={this.props.editable ? undefined : lnk}
            >
                <div className="iconplaceholder">
                    {icon}
                </div>
                <div className="detail">
                    {label}
                    {desc}
                </div>
            </AppLink>;
        }

        /* if (this.props.link.page || this.props.link.link){
                classes.push("clickable");
                onClick = this.linkClicked;
            } */

        return <div className={classes.join(" ")} onClick={onClick}>
            <div className="iconplaceholder">
                {icon}
            </div>
            <div className="detail">
                {label}
                {desc}
            </div>
        </div>;
    }
}
