/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import type { Entities } from '@inwink/entities/entities';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import type { ItemTemplateProps } from './itemtemplate.props';
import type { States } from '../../services/services';

interface IItemTemplatePictureProps extends ItemTemplateProps {
    showTextAsInitials?: boolean;
    hideTextIfLogo?: boolean;
    user?: any;
    i18nHelper?: Entities.i18nHelper;
    useImgTag?: boolean;
}
@withI18nHelper()
export class ItemTemplatePicture extends React.Component<IItemTemplatePictureProps, any> {
    render() {
        const i18n = this.props.i18nHelper;
        const photoField = this.props.template.fields[0];
        let photo;
        let photoUrl;
        let text;
        let ariaLabel;

        if (photoField) {
            photoUrl = getPropertyValue(this.props.data, this.props.template.fields[0].name);
        }

        if (photoUrl && typeof photoUrl !== "string") {
            photoUrl = photoUrl.thumbUrl ? photoUrl.thumbUrl : photoUrl.url;
        }

        if (photoUrl && photoUrl !== "NULL") {
            photo = {
                backgroundImage: `url("${photoUrl}")`
            };
        }

        // label accessibilité
        const ariaLabelFields = (this.props.template as any).ariaLabel;
        if (ariaLabelFields) {
            const txt = [];
            ariaLabelFields.forEach((f) => {
                if (f.name) {
                    txt.push(getPropertyValue(this.props.data, f.name));
                } else if (f.text) {
                    txt.push(i18n.translateBag(f.text));
                }
            });

            if (txt.length) {
                ariaLabel = txt.join(' ');
            }
        }

        const customfields = (this.props.template as any).customfields;
        if (customfields) {
            const txt = [];
            customfields.forEach((f) => {
                if (f.name) {
                    txt.push(getPropertyValue(this.props.data, f.name));
                } else if (f.text) {
                    txt.push(i18n.translateBag(f.text));
                }
            });

            if (this.props.showTextAsInitials) {
                const temp = [];
                txt.join(" ").split(" ").forEach((s) => {
                    if (s) {
                        temp.push(s[0].toUpperCase());
                    }
                });
                text = temp.join("");
            } else {
                text = txt.join(" ");
            }
        }
        const classname = (this.props.className || 'picture');
        let bloctext = <div className={classname + "-text"}><span className="text">{text}</span></div>;
        if (this.props.hideTextIfLogo && photoUrl) {
            bloctext = undefined;
        }
        const contentProps = {
            className: classname + "-photo",
            style: photo
        };

        if (ariaLabel) {
            contentProps["aria-label"] = ariaLabel;
        }

        if (this.props.useImgTag) {
            return <div className={`${classname} ${classname}img`}>
                {bloctext}
                {photoUrl ? (
                    <img src={photoUrl} loading="lazy" className={classname + "-photo"} alt={ariaLabel} />
                ) : null}
            </div>;
        }

        return <div className={classname}>
            {bloctext}
            {React.createElement("div", contentProps)}
        </div>;
    }
}

@connectwith((state: States.IAppState) => { return { user: state.user }; })
export class ItemTemplateUserPicture extends React.Component<IItemTemplatePictureProps, any> {
    constructor(props) {
        super(props);
        const state = {
            data: this.props.data
        };
        if (this.props.user?.currentUser?.data?.userDetail?.data
            && this.props.user.currentUser.data.userDetail.data[0]
            && this.props.user.currentUser.data.userDetail.data[0].id === this.props.data.id) {
            state.data = this.props.user.currentUser.data.userDetail.data[0];
        }
        this.state = state;
    }

    componentDidUpdate(prevProps: IItemTemplatePictureProps) {
        if (this.props.data !== prevProps.data) {
            let data = this.props.data;
            if (this.props.user?.currentUser?.data?.userDetail?.data
                && this.props.user.currentUser.data.userDetail.data[0]
                && this.props.user.currentUser.data.userDetail.data[0].id === this.props.data.id) {
                data = this.props.user.currentUser.data.userDetail.data[0];
            }
            this.setState({ data: data });
        }
    }

    render() {
        return <ItemTemplatePicture
            {...this.props}
            data={this.state.data}
            className={(this.props.className || "") + " userpicture"}
            showTextAsInitials
        />;
    }
}

export function ItemTemplateLogoPicture(props: IItemTemplatePictureProps) {
    return <ItemTemplatePicture {...props} className={(props.className || "") + " logo"} hideTextIfLogo />;
}
