/* eslint-disable react/display-name */
import * as React from 'react';
import { connect } from 'react-redux';
import { I18NResourcesContext } from '@inwink/i18n/resourcecontext';
import { i18NHelperContext } from '@inwink/i18n/reactcontext';
import type { States } from '@@services/services';
import { getTrads } from '@@data/templates';

export interface IAppTradsProps {
    event?: States.IEventState;
    rootwebsite?: States.IRootWebsiteState;
    community?: States.ICommunityState;
    trad: any;
    children?: React.ReactNode;
}
export interface IAppTradsState {
    eventtrads: any;
    websitetrads: any;
    communitytrads: any;
}
class AppTradsComponent extends React.PureComponent<IAppTradsProps, IAppTradsState> {
    constructor(props: IAppTradsProps) {
        super(props);
        this.state = {
            eventtrads: this.props.event?.data && getTrads(this.props.event.data),
            websitetrads: this.props.rootwebsite?.data && getTrads(this.props.rootwebsite.data),
            communitytrads: this.props.community?.data && getTrads(this.props.community.data)
        };
    }

    componentDidUpdate(prevprops: IAppTradsProps) {
        if (prevprops.event !== this.props.event && this.props.event.data) {
            const trads = getTrads(this.props.event.data);
            if (trads !== this.state.eventtrads) {
                this.setState({ eventtrads: trads });
            }
        }
        if (prevprops.rootwebsite !== this.props.rootwebsite && this.props.rootwebsite.data) {
            const trads = getTrads(this.props.rootwebsite.data);
            if (trads !== this.state.websitetrads) {
                this.setState({ websitetrads: trads });
            }
        }
        if (prevprops.community !== this.props.community && this.props.community.data) {
            const trads = getTrads(this.props.community.data);
            if (trads !== this.state.communitytrads) {
                this.setState({ communitytrads: trads });
            }
        }
    }

    render() {
        return <I18NResourcesContext
            resources={this.props.trad}
        >
            <I18NResourcesContext
                resources={this.state.websitetrads && this.state.websitetrads.labels}
            >
                <I18NResourcesContext
                    resources={this.state.communitytrads && this.state.communitytrads.labels}
                >
                    <I18NResourcesContext
                        resources={this.state.eventtrads && this.state.eventtrads.labels}
                    >
                        {this.props.children}
                    </I18NResourcesContext>
                </I18NResourcesContext>
            </I18NResourcesContext>
        </I18NResourcesContext>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        rootwebsite: state.rootwebsite
    };
}

export const AppTrads: new (any) => React.Component<IAppTradsProps, any> = connect(
    mapStateToProps,
    null
)(AppTradsComponent as any) as any;

export function withAppTrads(...trads: any[]) {
    let consolidated = trads[0];
    if (trads.length > 1) {
        consolidated = trads.reduce((buffer, current) => {
            const keys = Object.keys(current);
            const newBuffer = Object.assign({}, buffer);
            keys.forEach((k) => {
                newBuffer[k] = Object.assign({}, buffer[k], current[k]);
            });
            return newBuffer;
        }, {});
    }
    return function AppTradsInjection(target) {
        return React.forwardRef((props, ref) => <AppTrads trad={consolidated}>
            <i18NHelperContext.Consumer>
                {(i18nHelper) => React.createElement(target, {...props, i18nHelper: i18nHelper, ref: ref })}
            </i18NHelperContext.Consumer>
        </AppTrads>) as typeof target;
    };
}
