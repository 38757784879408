import * as React from 'react';
import type { ItemTemplateProps } from './itemtemplate.props';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { InwinkImage } from '@@components/images';

interface IInwinkImageTemplatePartProps extends ItemTemplateProps {    
}


export class InwinkImageTemplatePart extends React.Component<IInwinkImageTemplatePartProps, any> {
    render() {
        const image = getPropertyValue(this.props.data, this.props.template.properties?.target);
        return <InwinkImage
            className={this.props.className}           
            image={image}
            lazy={this.props.template.properties?.lazy}
            defaultSize={this.props.template.properties?.defaultSize}
            progressive={this.props.template.properties?.progressive === true
                || this.props.template.properties?.progressive === "true"
                || this.props.template.properties?.progressive === "1"}
            availableSizes={this.props.template.properties?.availableSizes}
        />;
    }
}